import styled from '@emotion/styled'
import mq from 'styles/breakpoints'

const SubscriptionPlans = styled.div`
  background: -webkit-gradient(
    linear,
    left top,
    0 200,
    from(#f5f5f5),
    to(white)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #213054;
  margin-bottom: 10rem;
  margin-top: 3rem;
  h1 {
    font-size: 6.2rem;
    font-weight: 300;
    padding-top: 5rem;
  }
  h4 {
    font-size: 3.2rem;
    font-weight: 400;
    margin-top: 1rem;
    text-align: center;
    span {
      color: #0080fa;
    }
  }

  ${mq['lg']} {
    h1 {
      font-size: 5rem;
    }

    h4 {
      font-size: 2.5rem;
    }
  }

  ${mq['sm']} {
    h1 {
      font-size: 4.5rem;
    }

    h4 {
      font-size: 2rem;
    }
  }
`

export default SubscriptionPlans
