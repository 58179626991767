import styled from '@emotion/styled'
import mq from 'styles/breakpoints'

const Textbox = styled.div`
  max-width: 690px;
  position: relative;
  padding-right: 5rem;
  padding-left: 5rem;
  padding-bottom: 5rem;
  font-size: 2rem;

  h2 {
    font-size: 4 rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  span {
    display: block;
    font-weight: 500;
    color: #0080fa;
  }

  ${mq['xl']} {
    font-size: 1.75rem;

    h2 {
      font-size: 2.5rem;
      font-weight: 700;
    }
  }

  ${mq['lg']} {
    font-size: 1.5rem;

    h2 {
      font-size: 2rem;
      font-weight: 700;
    }
  }

  ${mq['md']} {
    font-size: 2rem;

    h2 {
      font-size: 3rem;
      font-weight: 700;
    }
  }
`

export default Textbox
