import React from 'react'
import styled from '@emotion/styled'

import mq from 'styles/breakpoints'
import socialIcons from 'constants/social-icons'

const SocialSideBar = () => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 26rem;
    right: 0;
    height: 26em;
    width: 7rem;
    padding: 2.8rem 0;
    border-radius: 5px;
    background-color: #0080fa;

    ${mq['xl']} {
      top: 19rem;
      width: 6rem;
    }

    ${mq['lg']} {
      top: 13rem;
      width: 5rem;
    }

    ${mq['md']} {
      display: none;
    }

    a {
      color: white;
      font-size: 3.2rem;
      text-decoration: none;
      height: 2.8rem;
      transition: all 0.3s ease-in;
      cursor: pointer;

      ${mq['lg']} {
        font-size: 2.8rem;
        height: 2.5rem;
      }

      &:hover {
        color: #e6e6e6;
      }
    }
  `

  return (
    <Container>
      {socialIcons.map((item, index) => {
        return (
          <a
            key={index}
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.icon}
          </a>
        )
      })}
    </Container>
  )
}

export default SocialSideBar
